<template>
  <v-row justify="center" align="center">
    <v-col cols="12" xs="12" sm="8" md="4" lg="4">
      <v-card flat justify-center>
        <v-card-title primary-title>
          Покупка школьной карты
        </v-card-title>
        <v-card-text>
          <span>Использовать только для покупки школьной карты учащимся образовательных организаций Кировской области.</span>
          <br><br>
          <span>Выберите образовательную организацию:</span>
          <v-radio-group v-model="selectedSchool">
            <v-radio
              v-for="school in schools"
              :key="school.id"
              :label="school.name"
              :value="school.id"
            >
            </v-radio>
          </v-radio-group>
          <v-text-field
            v-model="position"
            name="positionname"
            label="Введите класс"
            id="positionname"
            outlined
            dense
            class="shrink-text-field"
          ></v-text-field>
          <v-text-field
            v-model="fullname"
            name="fullname"
            label="Введите ФИО владельца школьной карты"
            id="fullname"
            width="50px"
            outlined
            dense
          ></v-text-field>
          <span>Сумма: <strong>{{ amount }} рублей</strong></span>
          <br><br>
          <v-btn
            dense
            outlined
            color="blue"
            @click="generateQr"
          >Купить</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HomeView',
  data: () => ({
    schools: [
      { id: 0, name: 'МКОУ СОШ "Образовательный Центр" г.Зуевка' },
      { id: 1, name: 'МОКУ СОШ пгт Мирный' },
      { id: 2, name: 'КОГОБУ СШ пгт Нижнеивкино Куменского района' },
      { id: 3, name: 'КОГОБУ СШ с УИОП г. Нолинска' },
      { id: 4, name: 'КОГОБУ СШ пгт Оричи' }
    ],
    position: '',
    selectedSchool: 0,
    fullname: '',
    amount: 100
  }),
  methods: {
    async generateQr () {
      await this.$router.push({
        name: 'qr',
        query: {
          school: this.schools.find(x => x.id === this.selectedSchool).name,
          positionname: this.position,
          fullname: this.fullname,
          amount: this.amount
        }
      })
    }
  }
}
</script>
<style lang="css">
  .shrink-text-field {
    width: 200px;
    max-width: 200px;
  }
</style>
